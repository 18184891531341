import { useState } from 'react';

function useAccordion({ defaultExpanded = null, alwaysOneExpanded = false }) {
  const [indexExpanded, setIndexExpanded] = useState(defaultExpanded);

  const handleExpanded = (index) => {
    setIndexExpanded((prevExpanded) => {
      if (alwaysOneExpanded && prevExpanded === index) {
        return prevExpanded;
      }

      return (prevExpanded === index ? null : index);
    });
  };

  return {
    indexExpanded,
    handleExpanded,
  };
}

export default useAccordion;
