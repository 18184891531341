import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Styles
import styles from './AccordionBlock.module.css';

// Components
import Accordion from '../../atoms/Accordion/Accordion';

// Hooks
import useAccordion from '../../../src/hooks/useAccordion';

function AccordionBlock({ list, theme }) {
  const { indexExpanded, handleExpanded } = useAccordion({ defaultExpanded: 0 });

  return (
    <div className={cn([styles.wrapper, styles[theme]])}>
      <div className={styles.container}>
        {list?.length ? list.map(({ data: item }, index) => (
          <Accordion
            key={`${item?.title}${index}`}
            item={item}
            isExpanded={indexExpanded === index}
            handleExpanded={() => handleExpanded(index)}
            theme={theme}
          />
        )) : null}
      </div>
    </div>
  );
}

AccordionBlock.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  })),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
};

AccordionBlock.defaultProps = {
  list: [],
  theme: 'light',
};

export default AccordionBlock;
