import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

// Utils
import { cn } from 'utils';
// Styles
import styles from './Accordion.module.css';

// Components
import { Picto } from '../Picto/Picto';
import RichTextContent from '../RichTextContent/RichTextContent';

function Accordion({
  item, isExpanded, handleExpanded, theme,
}) {
  return (
    <div className={cn([styles.item, styles[theme]])}>
      <motion.div
        initial={false}
        onClick={handleExpanded}
        className={styles.wrapper}
      >
        <span className={styles.container}>
          <h3 className={styles.title}>{item?.title}</h3>
          <Picto icon={isExpanded ? 'minus' : 'plus'} className={styles.picto} />
        </span>
      </motion.div>
      <AnimatePresence initial={false}>
        {isExpanded ? (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              collapsed: { height: 0, transition: { duration: 0.3 } },
              open: { height: 'auto', transition: { duration: 0.3 } },
            }}
            className={styles.content}
          >
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                collapsed: { opacity: 0, transition: { duration: 0.05 } },
                open: { opacity: 1, transition: { duration: 0.3, delay: 0.2 } },
              }}
            >
              <RichTextContent content={item?.content} />
            </motion.div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

Accordion.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  isExpanded: PropTypes.bool,
  handleExpanded: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
};

Accordion.defaultProps = {
  item: {},
  isExpanded: false,
  handleExpanded: () => {},
  theme: 'light',
};

export default Accordion;
